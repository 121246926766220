<template>

	<main id="content">
		<RouterView v-slot="{Component}">
			<template v-if="Component">
				<Transition name="fade" mode="out-in">
					<KeepAlive>
						<component :is="Component" :key="$route.path"/>
					</KeepAlive>
				</Transition>
			</template>
		</RouterView>
	</main>

	<footer>
		
	</footer>
	
</template>

<script setup>

</script>

<style lang="scss">
	* {
		position: relative;
		box-sizing: border-box;
	}
	html {
		background: #fff;
		scroll-behavior: smooth;
		color: #333;
	}
	body {
		margin: 0;
		padding: 0;
	}
	button {
		font-family: "Oxygen";
		border: none;
		background: none;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 2px 0;

		svg {
			font-size: 17px;
			pointer-events: none;
		}
	}
	a {
		color: #2a2a2a;
	}
	

</style>
