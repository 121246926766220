import { createRouter, createWebHistory } from 'vue-router'

let mySavedPosition = 0;

const routes = [
  {
    path: '/:pathMatch(.*)*',
    name: 'Home',
    component: () => {
        document.title = 'be.love - Coming Soon';
        return import('@/views/ComingSoon.vue')
    },
  },
]

const router = createRouter({
  history: createWebHistory('/'),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || new Promise((resolve) => {
      setTimeout(() => resolve({ top: mySavedPosition }), 650)
    })
  },
})

router.beforeEach((to, from) => {
  
  if (from.path === '/') {
    mySavedPosition = window.scrollY;
  }
})
export default router